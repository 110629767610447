<template>
  <ui-container
    v-if="roomStore.room"
    class="flex flex-col h-full flex-1"
    no-gutters-x
  >
    <client-only>
      <div class="flex flex-col flex-1 h-full lg:gap-7 gap-5">
        <div
          v-if="isSm"
          class="grid grid-cols-[32px_1fr_32px] sm:grid-cols-1 gap-2.5"
        >
          <div>
            <ui-back-link is-small />
          </div>
          <common-room-main-info
            :room="roomStore.room"
            class="flex-1 overflow-hidden"
          />
          <ui-button
            v-if="isAllowEditSettings"
            variant="secondary"
            size="sm-icon"
            icon-left="edit"
            :to="{
              name: 'dashboard-rooms-roomId-settings',
              params: { roomId: roomStore.roomId }
            }"
          />
        </div>
        <ui-card class="flex flex-col flex-1 h-full">
          <div class="grid 2xl:grid-cols-2 grid-cols-1 gap-7 overflow-auto max-h-full pl:pr-5 pr-3 lg:-mr-4 -mr-2">
            <div>
              <div class="grid grid-cols-1 gap-5 divide-y divide-card-placeholder-border">
                <div class="grid grid-cols-1 gap-5">
                  <common-room-main-info
                    v-if="!isSm"
                    :room="roomStore.room"
                  >
                    <template #before>
                      <ui-back-link class="mr-5 self-start" />
                    </template>
                    <template #after-title>
                      <common-room-admin-settings-btn
                        v-if="isAllowEditSettings"
                        :room-id="roomStore.roomId"
                      />
                    </template>
                  </common-room-main-info>

                  <common-room-copy-invitation-link />
                </div>
                <common-room-additional-info
                  :room="roomStore.room"
                  class="pt-5"
                />
                <div
                  v-if="!roomStore.userStatus.guest"
                  class="flex"
                >
                  <div class="pt-5 grid grid-cols-1 gap-5">
                    <ui-button-async
                      v-if="roomStore.userPermission.DeleteRoom"
                      variant="error-link"
                      hide-space
                      size="md"
                      text="Delete This Room"
                      :check-confirm="confirmDeleteRoom"
                      :request="roomStore.onDeleteRoom"
                      @success="goToRooms"
                    />
                    <ui-button-async
                      v-if="!roomStore.userStatus.owner"
                      variant="error-link"
                      hide-space
                      size="md"
                      text="Leave This Room"
                      :check-confirm="confirmLeaveRoom"
                      :request="roomStore.onLeaveRoom"
                      @success="goToRooms"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isXl">
              <common-room-user-base-card
                v-if="roomStore.room.owner"
                :user="roomStore.room.owner"
                :status="RoomUserStatus.Owner"
              />

              <common-room-user-list-section v-if="!onlyPublicRoom" />
            </div>
          </div>
        </ui-card>

        <common-room-user-base-card
          v-if="roomStore.room.owner && isXl"
          :room-id="roomStore.roomId"
          :user="roomStore.room.owner"
          :status="RoomUserStatus.Owner"
        />
        <ui-card v-if="isXl && hasEditable">
          <common-room-user-list-section />
        </ui-card>

        <ui-button-async
          v-if="actionConfig"
          :icon-left="actionConfig.iconLeft"
          :text="actionConfig.text"
          class="max-w-md mx-auto w-full"
          :variant="actionConfig?.variant"
          :request="actionConfig.request"
          @success="actionConfig.onSuccess"
        />
      </div>
    </client-only>
  </ui-container>
</template>

<script setup lang="ts">
  import type { TRoomUserStatus } from '~/globals/rooms';

  type ActionConfigType = AsyncButtonProps & { onSuccess?: () => any };
  const { onlyPublicRoom, hasEditable } = useCurrentRoomPermissions();

  const isSm = useIsLessThan('sm');
  const isXl = useIsLessThan('xl');
  const roomStore = useRoomStore();
  const { routerPush } = useRouterLocale();

  const { open: openConfirmDeleteRoomModal } = useConfirmModal({});
  const isAllowEditSettings = computed(() => roomStore.userStatus.owner || roomStore.userPermission.UpdateRoomDetails);
  const confirmDeleteRoom = () =>
    new Promise<boolean>(resolve => {
      openConfirmDeleteRoomModal({
        title: 'Do you really want to delete this room?',
        confirmText: 'Yes, Delete',
        onSuccess: () => {
          resolve(true);
        }
      });
    });

  const confirmLeaveRoom = () =>
    new Promise<boolean>(resolve => {
      openConfirmDeleteRoomModal({
        title: 'Do you really want to leave this room?',
        confirmText: 'Yes, Leave',
        onSuccess: () => {
          resolve(true);
        }
      });
    });

  const actionConfig = computed<ActionConfigType | null>(() => {
    if (roomStore.userStatus.guest) {
      return {
        iconLeft: roomStore.roomType.room ? 'favorite' : 'thumb_up',
        text: 'Join',
        request: () => roomStore.onJoinRoom(),
        onSuccess: () => onUpdateUserRoomStatus(RoomUserStatus.Waiting)
      };
    }

    if (roomStore.userStatus.waiting) {
      return {
        iconLeft: 'block',
        text: 'Cancel Request',
        variant: 'secondary',
        request: () => roomStore.onLeaveRoom(),
        onSuccess: () => onUpdateUserRoomStatus(undefined)
      };
    }

    return null;
  });
  const onUpdateUserRoomStatus = (status?: TRoomUserStatus) => {
    const userStatus = status ? ({ status } as RoomUserStatusType) : undefined;
    roomStore.updateRoomStatus(userStatus);
  };
  const goToRooms = () => {
    routerPush({ name: 'dashboard-rooms' });
  };
</script>
